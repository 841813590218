import Link from 'next/link';
import { useRouter } from 'next/router';

import React from 'react';

import { css } from '@emotion/react';
import { HeaderV2Options } from '@type/web/header';

import useRouterHook from '@lib/hook/useRouterHook';
import { palette } from '@lib/styles/palette';

import Icon from '@components/common/Icon';
import Logo from '@components/common/Logo/NewLogo';
import SpaceLayout from '@components/web/Layout/SpaceLayout';
import RankKeyword from '@components/web/RankKeyword/RankKeyword';

interface DesktopTopHeaderProps {
  togglePopup: () => void;
  title?: HeaderV2Options['title'];
}
const DesktopTopHeader = ({
  togglePopup,
  title,
}: DesktopTopHeaderProps): JSX.Element => {
  const router = useRouter();
  const { query } = router;
  const { keyword } = query;
  return (
    <div css={wrapper}>
      <SpaceLayout padding={[16, 16]}>
        <div css={primaryPC(keyword)}>
          <Link href="/">
            <a>
              <Logo width={159} height={25} />
            </a>
          </Link>
          <div className="searchWrap" onClick={togglePopup}>
            <button type="button" aria-label="keyword" className="searchBar">
              {keyword || '어떤 상품을 찾으시나요?'}
            </button>
            <div className="searchIcon">
              <Icon icon="search" size={20} stroke={palette.gray6} />
            </div>
          </div>
          <div className="rank">
            <RankKeyword />
          </div>
        </div>
      </SpaceLayout>
      {title?.text && title?.showTitle.includes('desktop') && (
        <div css={bottomTitle}>
          <h1>{title.text}</h1>
        </div>
      )}
    </div>
  );
};

export default DesktopTopHeader;

const wrapper = css`
  display: none;
  @media screen and (min-width: 1024px) {
    display: block;
  }
`;
const primaryPC = (keyword: string | string[] | undefined) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .category {
    margin-right: 30px;
  }

  .searchWrap {
    flex: 1;
    margin: 0 5%;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .searchBar {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      height: 44px;
      padding: 0 20px;
      border: none;
      border-radius: 30px;
      background: ${palette.gray0};
      box-shadow: none;
      text-align: left;
      font-size: 14px;
      color: ${keyword ? palette.black10 : palette.gray5};
    }
    .searchIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 10px;
    }
  }
  .rank {
    width: 244px;
    height: 44px;
    position: relative;
    z-index: 2;
    .rank {
      width: 100%;
      height: auto;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
`;

const bottomTitle = css`
  display: none;
  h1 {
    display: none;
  }
  @media screen and (min-width: 1024px) {
    width: 100%;
    margin: 0 auto;
    height: 56px;
    display: grid;
    place-items: center;
    background-color: white;
    z-index: 2;
    border-bottom: 1px solid #e0e0e0;
    top: 72px;
    h1 {
      display: block;
    }
  }
`;
