import { useEffect, useState } from 'react';

import { produce } from 'immer';
import { useRecoilValue } from 'recoil';

import { priceRange } from '@common/const';
import { formatNumber } from '@common/parser';

import FilterLocation from '@components/web/search/common/filter/FilterLocation';
import FilterPriceRange from '@components/web/search/common/filter/FilterPriceRange';

import FilterCategoryList from '../common/filter/FilterCategoryList';

import { searchFilterData, searchFilterDataV3 } from '@recoils/search/atom';

const useSearchFilter = () => {
  // 검색필터 데이터
  const filterData = useRecoilValue(searchFilterDataV3);

  const initialFilters = [
    {
      title: '카테고리',
      placeholder: '',
      query: ['category'],
      component: FilterCategoryList,
      active: false,
      isOpen: true,
    },
    {
      title: '동네',
      placeholder: '',
      query: ['lon', 'lat'],
      component: FilterLocation,
      active: false,
      isOpen: true,
    },
    {
      title: '가격',
      placeholder: '',
      query: ['minPrice', 'maxPrice'],
      component: FilterPriceRange,
      active: false,
      isOpen: true,
    },
  ];

  const [filters, setFilters] = useState(initialFilters);

  const toggleFilterOpen = (num: number) => {
    let newFilter = [...filters];

    newFilter = produce(newFilter, (draft) => {
      const target = draft[num];
      target.isOpen = !target.isOpen;
    });
    setFilters(newFilter);
  };

  const init = () => {
    let newFilter = [...filters];

    if (filterData.categoryPath && filterData.categoryPath.length > 0) {
      const placeholder = filterData.categoryPath.reduce((acc, cur) => {
        return (acc.length !== 0 ? `${acc} > ` : '') + cur.categoryName;
      }, '');

      newFilter = produce(newFilter, (draft) => {
        const cate = draft[0];
        cate.placeholder = placeholder;
        cate.active = true;
      });
    } else {
      newFilter = produce(newFilter, (draft) => {
        const cate = draft[0];
        cate.placeholder = '';
        cate.active = false;
      });
    }

    // if (filterData.locationFilter && filterData.locations.length > 0) {
    if (filterData.locationFilter && filterData.locationName) {
      // const placeholder = filterData.locations[0].locationName;
      const placeholder = filterData.locationName;
      newFilter = produce(newFilter, (draft) => {
        const locations = draft[1];
        locations.placeholder = placeholder;
        locations.active = true;
      });
    } else {
      newFilter = produce(newFilter, (draft) => {
        const locations = draft[1];
        locations.placeholder = '';
        locations.active = false;
      });
    }

    if (filterData.priceFilter) {
      const min = filterData.priceFilter.minPrice;
      const max = filterData.priceFilter.maxPrice;

      const findPriceFilterIdx = priceRange.findIndex(
        (d) => d.value[0] === min && d.value[1] === max,
      );

      const placeholder = priceRange[findPriceFilterIdx]?.text;
      newFilter = produce(newFilter, (draft) => {
        const price = draft[2];
        price.placeholder =
          placeholder ??
          `${formatNumber(String(min))}원 ~ ${formatNumber(String(max))}원`;
        price.active = true;
      });
    } else {
      newFilter = produce(newFilter, (draft) => {
        const price = draft[2];
        price.placeholder = '';
        price.active = false;
      });
    }

    setFilters(newFilter);
  };

  useEffect(() => init(), [filterData]);

  return {
    filters,
    init,
    toggleFilterOpen,
  };
};

export default useSearchFilter;
