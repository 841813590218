import Link from 'next/link';
import { useRouter } from 'next/router';

import {
  ChangeEvent,
  Fragment,
  KeyboardEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useQuery } from 'react-query';

import useEmblaCarousel from 'embla-carousel-react';

import { MOBILE_WIDTH, TABLET_WIDTH } from '@common/constants/MEDIAS';
import { css } from '@emotion/react';

import useRecentSearches from '@lib/hook/useRecentSearches';
import { palette } from '@lib/styles/palette';

import * as SearchAPI from '@api/search/search';

import Button from '@components/common/Button';
import Icon from '@components/common/Icon';

interface SearchBarProps {
  togglePopup: () => void;
}
type Tab = 'product' | 'store';

const SearchBar = ({ togglePopup }: SearchBarProps): JSX.Element => {
  const [autoCompleteList, setAutoCompleteList] = useState<string[]>([]);
  const [tab, setTab] = useState<Tab>('product');
  const [keyword, setKeyword] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const router = useRouter();
  const { recentSearches, clearRecentSearches, addRecentSearches } =
    useRecentSearches();
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    dragFree: true,
    containScroll: 'trimSnaps',
  });
  // queryString의 검색어 추출
  const searchKeyword = useMemo(
    () =>
      typeof router.query.keyword === 'string' ? router.query.keyword : '',
    [router.query.keyword],
  );

  const { data: productRes } = useQuery(
    ['get-product-autocomplete-keyword', keyword],
    () => (keyword ? SearchAPI.getRecommendKeywords(keyword) : undefined),
    {
      enabled: !!keyword && tab === 'product',
      staleTime: 60000,
      onError(err) {
        console.dir(`검색 자동완성 상품명 리스트 API 호출 에러: ${err}`);
      },
    },
  );
  const { data: storeRes } = useQuery(
    ['get-store-autocomplete-keyword', keyword],
    () => (keyword ? SearchAPI.getRecommendStoreKeywords(keyword) : undefined),
    {
      enabled: !!keyword && tab === 'store',
      staleTime: 60000,
      onError(err) {
        console.dir(`검색 자동완성 가게명 리스트 API 호출 에러: ${err}`);
      },
    },
  );

  useEffect(() => {
    if (searchKeyword) addRecentSearches(searchKeyword);
  }, []);

  useEffect(() => {
    setAutoCompleteList(
      tab === 'product'
        ? productRes
          ? productRes?.data.autoCompleteItemList.map(
              (keyword) => keyword.keyword,
            )
          : []
        : storeRes
        ? storeRes.data.keywordList.map((keyword) => keyword.nickName)
        : [],
    );
  }, [productRes, storeRes, keyword, tab]);

  const onChangeInputValue = async () => {
    inputRef.current && setKeyword(inputRef.current?.value);
  };

  const clearInputValue = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
      setAutoCompleteList([]);
    }
  };

  const onClickRecentSearchKeyword = (keyword: string) => {
    // 카테고리 필터 적용 되어있을때 함께 적용 :
    // if (router.query.category) {
    //   return `/search/${encodeURIComponent(keyword)}?category=${
    //     router.query.category
    //   }`;
    // }
    return `/search/${encodeURIComponent(keyword)}`;
  };

  // 검색
  const onKeyPress = (e: KeyboardEvent<DebounceInput>) => {
    if (inputRef.current) {
      if (!inputRef.current.value && e.key === 'Enter') {
        togglePopup();
        return router.push({
          pathname: `/search`,
          query: { category: router.query.category || 0 },
        });
      } else if (inputRef.current.value && e.key === 'Enter') {
        togglePopup();
        router.push({
          pathname: `/search/${encodeURIComponent(inputRef.current.value)}`,
          query: { category: router.query.category || 0 },
        });
      }
    }
  };

  return (
    <Fragment>
      <div>
        <div css={inputWrap}>
          <Icon icon="search" size="1.3rem" stroke={palette.gray5} />
          <DebounceInput
            inputRef={inputRef}
            type="text"
            id="searchStr"
            name="상품 검색"
            placeholder="검색어를 입력해주세요."
            onChange={onChangeInputValue}
            onKeyPress={onKeyPress}
            autoComplete="off"
            debounceTimeout={500}
            value={(inputRef?.current && inputRef?.current.value) || ''}
          />
          {inputRef.current && inputRef.current.value && (
            <Button type="link" padding="0" onClick={clearInputValue}>
              <Icon
                size="1.3rem"
                icon="xCircleFill"
                stroke={palette.white}
                color={palette.gray5}
              />
            </Button>
          )}
        </div>
      </div>

      {
        /* 최근 검색어 */
        autoCompleteList.length === 0 ? (
          <div css={searchKeywordContainer}>
            <p className="title">
              최근 검색어
              {!!recentSearches?.length && (
                <Button
                  type="link"
                  fontSize="0.8rem"
                  color={palette.gray6}
                  padding="0"
                  onClick={clearRecentSearches}
                >
                  모두 지우기
                </Button>
              )}
            </p>

            {recentSearches?.length ? (
              <div className="recent" ref={emblaRef}>
                <ul className="list">
                  {recentSearches.map((keyword: string) => (
                    <li css={recentItem} key={keyword}>
                      <Button
                        size="small"
                        color={palette.gray6}
                        borderColor={palette.gray0}
                        backgroundColor={palette.gray0}
                        shape="round"
                        to={onClickRecentSearchKeyword(keyword)}
                        onClick={togglePopup}
                      >
                        {keyword.length > 8
                          ? `${keyword.slice(0, 8)}⋯`
                          : keyword}
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              /* -------------------- */
              <p className="noList">최근 검색어가 없습니다.</p>
            )}
          </div>
        ) : (
          <Fragment>
            <div css={tabStyle}>
              <button
                className={tab === 'product' ? 'tab' : ''}
                onClick={() => setTab('product')}
              >
                상품
              </button>
              <button
                className={tab === 'store' ? 'tab' : ''}
                onClick={() => setTab('store')}
              >
                가게
              </button>
            </div>
            {tab === 'product' && (
              /* 상품 검색어 추천 리스트 */
              <ul css={autoCompleteWrap}>
                {autoCompleteList.map((item, i) => (
                  <li key={`${item}${`_${i}`}`}>
                    <Link href={`/search/${encodeURIComponent(item)}`}>
                      <a className="link" onClick={togglePopup}>
                        {item}
                        <Icon icon="arrow_up_left" stroke={palette.gray4} />
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            )}
            {tab === 'store' && (
              /* 가게 검색어 추천 리스트 */
              <ul css={autoCompleteWrap}>
                {autoCompleteList.map((item, i) => (
                  <li key={`${item}${`_${i}`}`}>
                    <Link
                      href={`/search-store?keyword=${encodeURIComponent(item)}`}
                    >
                      <a className="link" onClick={togglePopup}>
                        {item}
                        <Icon icon="arrow_up_left" stroke={palette.gray4} />
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </Fragment>
        )
        /* ----------------------*/
      }
    </Fragment>
  );
};

export default SearchBar;

const inputWrap = css`
  margin: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 2.5rem;
  padding: 0 0.8rem;
  background: ${palette.gray0};
  border-radius: 0.5rem;
  input {
    width: 100%;
    height: 100%;
    padding: 0 0.4rem;
    background: none;
    border: none;
    outline: none;
    font-size: 0.95rem;
    color: #333;
  }

  input::placeholder {
    color: #ccc;
  }
`;

const autoCompleteWrap = css`
  margin-top: 16px;
  min-height: 104px;
  li {
    &:not(:last-child) {
      border-bottom: 1px solid ${palette.gray0};
    }

    .link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 52px;
      color: ${palette.black10};
    }
  }
`;

const searchKeywordContainer = css`
  padding: 40px 0 70px 0;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 500;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 8px;
  }

  .noList {
    margin-top: 30px;
    text-align: center;
    color: ${palette.gray5};
    font-size: 14px;
  }

  @media (max-width: ${TABLET_WIDTH}) {
    padding: 20px 0 30px 0;
  }

  @media (max-width: ${MOBILE_WIDTH}) {
    .list li {
      flex-shrink: 0;
    }

    .recent {
      overflow-x: hidden;

      .list {
        flex-wrap: nowrap;
      }
    }
  }
`;

const recentItem = css`
  a:hover {
    color: ${palette.gray6};
    border-color: ${palette.gray0};
    background-color: ${palette.gray0};
  }
`;

const tabStyle = css`
  margin-top: 1em;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  color: ${palette.gray4};

  button {
    width: 50%;
    background-color: transparent;
    height: 3em;
    position: relative;
    box-sizing: content-box;
    border-bottom: 2px solid ${palette.gray4};
  }

  .tab {
    color: black;
    border-bottom: 2px solid black;
  }
`;
