import Link from 'next/link';
import { useRouter } from 'next/router';

import React, { useCallback, useMemo } from 'react';
import { isAndroid, isDesktop } from 'react-device-detect';

import { useRecoilValue } from 'recoil';

import { APP_DOWNLOAD_WEB_URL, ITUNES, PLAYSTORE } from '@common/const';
import { MOBILE_MEDIA, TABLET_MEDIA } from '@common/constants/MEDIAS';
import getDeepLink from '@common/deepLinking/getDeepLink';
import useOpenApp from '@common/deepLinking/useOpenApp';
import useOpenAppWithWebUrl from '@common/deepLinking/useOpenAppWithWebUrl';
import { css } from '@emotion/react';
import { HeaderV2Options } from '@type/web/header';

import { palette } from '@lib/styles/palette';

import Button from '@components/common/Button';
import Icon from '@components/common/Icon/Icon';
import Logo from '@components/common/Logo';

import SpaceLayout from '../Layout/SpaceLayout';

import { searchFilterDataV3 } from '@recoils/search/atom';

interface MobileTopHeaderProps {
  showAppDownloadBtn: boolean;
  toggleCategoryMenu: () => void;
  title?: HeaderV2Options['title'];
}
const MobileTopHeader = ({
  showAppDownloadBtn,
  toggleCategoryMenu,
  title,
}: MobileTopHeaderProps) => {
  const { query } = useRouter();
  const { keyword } = query;
  const openAppWithWebUrl = useOpenAppWithWebUrl();
  const handleOpenApp = () => {
    openAppWithWebUrl();
  };
  const { categoryPath } = useRecoilValue(searchFilterDataV3);
  const searchTitle = useMemo(() => {
    if (categoryPath.length > 0) {
      return categoryPath[categoryPath.length - 1].categoryName;
    }
    if (keyword) {
      return keyword;
    }

    return '전체 카테고리';
  }, [categoryPath]);

  return (
    <div css={mobileHeader}>
      <SpaceLayout>
        <div css={mobileHeaderLayout}>
          <Link href="/">
            <a className="logo">
              <Logo logoType="ONLY_ICON" color={palette.orange9} />
            </a>
          </Link>
          {title?.showTitle && (
            <h1 css={titleStyles(title.showTitle)} className="title">
              {title?.text || searchTitle}
            </h1>
          )}
          <div css={mobileHeaderRightButtons}>
            {showAppDownloadBtn && (
              <Button
                type="light"
                shape="round"
                padding="7px 8px"
                styleProps={mobileOnly}
                fontSize="12px"
                height="28px"
                onClick={handleOpenApp}
                className="goToApp"
              >
                앱으로 보기
              </Button>
            )}
            <div className="toggleMenuBtn" onClick={toggleCategoryMenu}>
              <Button type="link" padding="0">
                <Icon icon={'category'} size={24} stroke={palette.black10} />
              </Button>
            </div>
          </div>
        </div>
      </SpaceLayout>
    </div>
  );
};

export default MobileTopHeader;

const mobileHeader = css`
  @media (min-width: 1024px) {
    display: none;
  }
`;

const mobileHeaderLayout = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  .logo {
    min-width: 44px;
  }
  /* .title {
    max-width: 50%;
  }

  .hide-title {
    display: none;

    @media (max-width: 599px) {
      display: block;
    }
  } */
`;

const titleStyles = (showTitle: Array<'mobile' | 'tablet' | 'desktop'>) => css`
  display: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${TABLET_MEDIA} {
    ${showTitle.includes('tablet') && 'max-width: 50%; display: block;'}
  }
  ${MOBILE_MEDIA} {
    ${showTitle.includes('mobile') && 'max-width: 50%; display: block;'}
  }
`;

const mobileHeaderRightButtons = css`
  display: flex;
  gap: 15px;
`;

const mobileOnly = css`
  display: none;

  @media (max-width: 1023px) {
    display: inline;
  }
`;
