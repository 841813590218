import React from 'react';

import categoryMenu from '@data/categoryMenu.json';
import { CategoryMenuType } from '@type/web/category';

import FilterCategoryItem from './FilterCategoryItem';

const FilterCategoryList = () => {
  return (
    <div>
      {categoryMenu?.map((v: CategoryMenuType) => (
        <FilterCategoryItem key={v?.seq} category={v} parentSeqs={[v.seq]} />
      ))}
    </div>
  );
};

export default FilterCategoryList;
