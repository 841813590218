import {
  ReactElement,
  useState,
  useEffect,
  FormEvent,
  ChangeEvent,
  Ref,
  forwardRef,
  Fragment,
} from 'react';

import { Radio, Space, RadioChangeEvent, Input } from 'antd';
import { useRecoilValue, useRecoilState } from 'recoil';

import { priceRange } from '@common/const';
import { formatNumber } from '@common/parser';
import { onlyNumber } from '@common/regExp';
import { css } from '@emotion/react';

import useResponsive from '@hook/useResponsive';
import useRouterHook from '@hook/useRouterHook';

import { categorys } from '@recoils/category/atom';
import { searchFilterData, mobileFilterSave } from '@recoils/search/atom';

function FilterPriceRange(
  { props }: any,
  ref: Ref<HTMLDivElement>,
): ReactElement {
  const { isPC } = useResponsive();
  const { pushQueryString, queryString, router } = useRouterHook();
  const [priceRadio, setPriceRadio] = useState(0);
  const [directMinPrice, setDirectMinPrice] = useState('');
  const [directMaxPrice, setDirectMaxPrice] = useState('');

  // 카테고리 객체
  const category = useRecoilValue(categorys);

  // 검색필터 데이터
  const [searchFilter, setSearchFilter] = useRecoilState(searchFilterData);
  const [saveMobileFilter, setSaveMobileFilter] =
    useRecoilState(mobileFilterSave);

  const activeFilter = (parameter: Record<string, string>) => {
    if (isPC) {
      const path = router.asPath.split('?')[0];
      pushQueryString(parameter, path);
    } else {
      setSaveMobileFilter({
        ...saveMobileFilter,
        ...parameter,
      });
    }
  };

  const changeMinPrice = (e: ChangeEvent<HTMLInputElement>) => {
    const val = formatNumber(e.target.value);
    setDirectMinPrice(val);
  };

  const changeMaxPrice = (e: ChangeEvent<HTMLInputElement>) => {
    const val = formatNumber(e.target.value);
    setDirectMaxPrice(val);
  };

  // 검색필터 변경 : 가격범위
  const pushPriceRange = (value: [number, number]) => {
    setSearchFilter({
      ...searchFilter,
      priceRange: value,
    });

    const parameter: Record<string, string> = {
      minPrice: value[0].toString(),
      maxPrice: value[1].toString(),
    };

    activeFilter(parameter);
  };

  // 검색필터 변경 : 가격범위
  const changePriceRange = (e: RadioChangeEvent) => {
    const { value } = e.target;

    setPriceRadio(value);

    if (priceRange.length - 1 !== value) {
      const priceRangeValue = priceRange[value].value;

      const min = priceRangeValue[0];
      const max = priceRangeValue[1];

      const minStr = formatNumber(min.toString());
      const maxStr = formatNumber(max.toString());
      setDirectMinPrice(minStr);
      setDirectMaxPrice(maxStr);

      pushPriceRange(priceRangeValue);
    }
  };

  const onSubmitPriceRange = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const min = directMinPrice
      ? Number(onlyNumber(directMinPrice))
      : priceRange[0].value[0];
    const max = directMaxPrice
      ? Number(onlyNumber(directMaxPrice))
      : priceRange[0].value[1];
    pushPriceRange([min, max]);
  };

  const init = () => {
    const { minPrice, maxPrice } = queryString;

    if (minPrice || maxPrice) {
      const min = minPrice ? Number(minPrice) : priceRange[0].value[0];
      const max = maxPrice ? Number(maxPrice) : '';

      const target = priceRange.reduce((acc, cur) => {
        const val = cur.value;
        const bool = val[0] === min && val[1] === max;
        return bool ? cur.key : acc;
      }, priceRange.length - 1);

      const minStr = formatNumber(min.toString());
      const maxStr = formatNumber(max.toString());

      setPriceRadio(target);
      setDirectMinPrice(minStr);
      setDirectMaxPrice(maxStr);
    }
  };

  useEffect(() => {
    init();
  }, [category]);

  return (
    <div ref={ref}>
      <Radio.Group
        css={radioWrap}
        onChange={changePriceRange}
        value={priceRadio}
        className="price"
        size="middle"
      >
        <Space direction="vertical" size={0} css={radioSpace}>
          {priceRange.map((p) => (
            <Radio value={p.key} key={`가격범위-${p.key}`}>
              {p.text}
            </Radio>
          ))}
          {priceRange.length - 1 === priceRadio && (
            <Fragment>
              <form onSubmit={onSubmitPriceRange}>
                <div css={writePriceRange}>
                  <Input
                    type="tel"
                    name="minPrice"
                    placeholder="최저가"
                    value={directMinPrice}
                    onChange={changeMinPrice}
                  />
                  <span>~</span>
                  <Input
                    type="tel"
                    name="maxPrice"
                    placeholder="최고가"
                    value={directMaxPrice}
                    onChange={changeMaxPrice}
                  />
                </div>
                <button type="submit" aria-label="submit" css={submitButton}>
                  적용
                </button>
              </form>
            </Fragment>
          )}
        </Space>
      </Radio.Group>
    </div>
  );
}

const radioWrap = css`
  width: 100%;
  margin-top: 8px;

  .ant-radio-wrapper {
    width: 100%;
    height: 48px;
    padding: 14px 0;

    & > span:last-of-type {
      width: 100%;
    }

    .ant-radio-inner {
      border-width: 5px;

      &::after {
        content: none;
      }
    }
  }
`;

const radioSpace = css`
  width: 100%;
`;

const writePriceRange = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;

  span {
    padding: 0 8px;
  }
`;

const submitButton = css`
  width: 100%;
  height: 40px;
  font-size: 14px;
  margin-top: 12px;
  border: 1px solid #ccc;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 4px;
`;

const FilterPriceRangeRef = forwardRef<HTMLDivElement>(FilterPriceRange);
export default FilterPriceRangeRef;
