import { useRouter } from 'next/router';

import {
  ReactElement,
  useState,
  useRef,
  useEffect,
  useMemo,
  Fragment,
} from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { DownOutlined } from '@ant-design/icons';
import { TABLET_WIDTH } from '@common/constants/MEDIAS';
import { css } from '@emotion/react';

import makeCssTextEllipsis from '@lib/styles/makeCssTextEllipsis';
import { palette } from '@lib/styles/palette';

import usePortal from '@hook/usePortal';
import useRouterHook from '@hook/useRouterHook';

import Button from '@components/common/Button/Button';
import Icon from '@components/common/Icon/Icon';
import SpaceLayout from '@components/web/Layout/SpaceLayout';
import useSearchFilter from '@components/web/search/hook/useSearchFilter';

import {
  searchFilterData,
  mobileFilterSave,
  searchFilterDataV3,
} from '@recoils/search/atom';

function SearchFilterMobile(): ReactElement {
  const { push, location, queryString, pushQueryString } = useRouterHook();
  const router = useRouter();
  const { query } = router;
  const { keyword, category } = query;
  const {
    PopUp: FilterPopUp,
    onTogglePopUp,
    isShowPopUp,
  } = usePortal('PagePopUp');
  // const isSearchPage = location.pathname.includes('/search');

  const { filters, toggleFilterOpen } = useSearchFilter();

  const filterData = useRecoilValue(searchFilterDataV3);
  const [saveMobileFilter, setSaveMobileFilter] =
    useRecoilState(mobileFilterSave);
  const [focusFilter, setFocusFilter] = useState(0);

  const activeCategoryName = useMemo(() => {
    const { categoryPath } = filterData;
    if (categoryPath.length > 0) {
      return categoryPath[categoryPath.length - 1].categoryName;
    }
    return '전체 카테고리';
  }, [filterData]);

  const breadCrumb = filterData.categoryPath
    .map((c) => c.categoryName)
    .join(' > ');

  const ref1 = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);
  const ref3 = useRef<HTMLDivElement>(null);

  const refs = [ref1, ref2, ref3];

  // queryString에 따른 초기값 설정
  const clearFilter = async () => {
    setSaveMobileFilter({});
    push('/search');
    // if (isCategoryPage) {
    //   push(`${location.pathname}`);
    // } else {
    //   const keywordQuery = keyword ? `/${keyword}` : '';
    //   const categoryQuery = category ? `?category=${category}` : '';
    //   push(`/search${keywordQuery}${categoryQuery}`);
    // }
  };
  // queryString에 따른 초기값 설정
  const moveFilter = async () => {
    const path = router.asPath.split('?')[0];
    pushQueryString(saveMobileFilter, path);
    onTogglePopUp();
  };

  useEffect(() => {
    if (ref1 && ref2 && ref3) {
      if (focusFilter !== 0) refs[focusFilter].current?.scrollIntoView();
    }
  }, [ref1.current, ref2.current, ref3.current]);

  return (
    <Fragment>
      <SpaceLayout padding={[12, 12]} border={['', 'solid 1px #f5f5f5']}>
        <ul css={filterWrap}>
          {filters.map((filter, i) => {
            const { title, placeholder, active } = filter;

            return (
              <li key={title}>
                <Button
                  type={active ? 'secondary' : 'light'}
                  size="small"
                  rightIcon={
                    active ? undefined : (
                      <Icon icon="arrowDown" size={12} stroke="#666" />
                    )
                  }
                  onClick={() => {
                    onTogglePopUp();
                    setFocusFilter(i);
                  }}
                  borderColor={active ? '' : '#666'}
                >
                  <p css={makeCssTextEllipsis({ lineCount: 1 })}>
                    {placeholder || title}
                  </p>
                </Button>
              </li>
            );
          })}
        </ul>
      </SpaceLayout>
      <FilterPopUp>
        <ul css={wrap}>
          {isShowPopUp &&
            filters.map((filter, i) => {
              const {
                title,
                active,
                isOpen,
                component: FilterComponent,
              } = filter;
              return (
                <li key={title}>
                  <button
                    aria-label={title}
                    css={button}
                    type="button"
                    onClick={() => toggleFilterOpen(i)}
                  >
                    <div css={filterName(active)}>{title}</div>
                    {title === '카테고리' && (
                      <span css={filterBreadCrumb}>{breadCrumb}</span>
                    )}
                    <DownOutlined css={icon} rotate={isOpen ? 180 : 0} />
                  </button>
                  {isOpen && (
                    <div css={content}>
                      <FilterComponent ref={refs[i]} />
                    </div>
                  )}
                </li>
              );
            })}
        </ul>
        <div css={buttonWrap}>
          <Button shape="square" type="gray" onClick={clearFilter}>
            <Icon icon="refresh" size={14} stroke="#a3a3a3" /> 초기화
          </Button>
          <Button shape="square" onClick={moveFilter}>
            상품보기
          </Button>
        </div>
      </FilterPopUp>
    </Fragment>
  );
}

const filterWrap = css`
  display: flex;

  li {
    margin-left: 8px;

    &:first-of-type {
      margin-left: 0;
    }
  }
`;

const wrap = css`
  padding-bottom: 72px;
  margin-top: 20px;
`;

const buttonWrap = css`
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100%;
  height: 72px;

  & > button {
    &:first-of-type {
      flex: 42;
    }
    &:last-of-type {
      flex: 58;
    }
  }
`;

const button = css`
  display: flex;
  width: 100%;
  padding: 30px 24px;
  border-bottom: solid 1px #e0e0e0;
  color: #0a0400;
  text-align: left;
  background: none;
`;
const filterName = (active: boolean) => css`
  position: relative;

  ${active &&
  css`
    &:after {
      content: '';
      position: absolute;
      top: -3px;
      right: -6px;
      width: 4px;
      height: 4px;
      border-radius: 4px;
      background-color: ${palette.orange9};
    }
  `}
`;
const filterBreadCrumb = css`
  margin-left: 28px;
  color: #a3a3a3;
  font-size: 14px;
`;
const icon = css`
  margin-left: auto;
`;
const content = css`
  margin: 8px 0 17px;
  padding: 0 24px;

  .ant-menu {
    color: #666;
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    margin: 0 !important;

    height: 48px !important;
    line-height: 48px !important;
    padding: 15px 0 !important;
    border-bottom: solid 1px #e0e0e0;

    &.depth2,
    &.depth2 ~ .ant-menu-submenu > .ant-menu-submenu-title {
      padding-left: 12px !important;
    }
    &.depth3 > span {
      padding-left: 24px !important;
    }

    &:hover {
      color: ${palette.orange9};
    }
  }

  .ant-menu-sub.ant-menu-inline {
    background: none;
  }
  .ant-menu-submenu-selected > div,
  .ant-menu-item-selected {
    color: ${palette.black10};
    font-weight: 500;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: none;
  }
  .ant-menu-submenu-arrow {
    display: none;
  }
`;

export default SearchFilterMobile;
