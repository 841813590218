import Link from 'next/link';

import React from 'react';

import { MOBILE_WIDTH } from '@common/constants/MEDIAS';
import { hexToRgba } from '@common/parser';
import { mainList } from '@data/categoryList';
import { css } from '@emotion/react';

import { palette } from '@lib/styles/palette';

interface CategoryListBtnsProps {
  togglePopup: () => void;
}

const CategoryListBtns = ({
  togglePopup,
}: CategoryListBtnsProps): JSX.Element => {
  const categoryListData = mainList?.data.category;

  return (
    <div css={categoryListWrapper}>
      <p className="title">카테고리</p>
      <ul className="list">
        {categoryListData?.map((item) => (
          <li
            key={`${item.categoryName}_${item.categorySeq}`}
            css={categoryItem}
            onClick={togglePopup}
          >
            <Link href={`/search?category=${item.categorySeq}`} passHref>
              <a
                css={categoryBtn}
                className={`header-category category-${item.categoryName}`}
              >
                {item.categoryName}
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoryListBtns;

const categoryListWrapper = css`
  padding: 20px 0 72px 0;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 500;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 8px;
  }

  .noList {
    margin-top: 30px;
    text-align: center;
    color: ${palette.gray5};
    font-size: 14px;
  }

  @media (max-width: ${MOBILE_WIDTH}) {
    .list li {
      flex-shrink: 0;
    }

    .recent {
      overflow-x: hidden;

      .list {
        flex-wrap: nowrap;
      }
    }
  }
  @media (min-width: 1024px) {
    display: none;
  }
`;

const categoryItem = css`
  a:hover {
    color: ${palette.black10};
    border-color: ${palette.gray2};
    background-color: ${palette.gray1};
  }
`;

const categoryBtn = css`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: none;
  outline: none;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  border-radius: 0.125rem;
  background-color: transparent;
  color: ${palette.black10};
  border: 1px solid ${hexToRgba(palette.black10, 0.3)};
  svg {
    color: ${palette.black10};
  }

  &:hover {
    color: ${hexToRgba(palette.black10, 0.7)};
    border: 1px solid ${hexToRgba(palette.black10, 0.6)};

    svg {
      stroke: ${hexToRgba(palette.black10, 0.7)};
    }
  }
  border-radius: 0.25rem;
`;
