import { css, SerializedStyles } from '@emotion/react';

interface MakeCssTextEllipsisPropsType {
  lineCount: number;
  lineHeight?: number;
}
export default function makeCssTextEllipsis({
  lineCount,
  lineHeight = 17,
}: MakeCssTextEllipsisPropsType): SerializedStyles {
  return css`
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${lineCount};
    -webkit-box-orient: vertical;
    line-height: ${lineHeight}px;
    height: ${lineCount * lineHeight}px;
  `;
}
