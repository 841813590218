import router from 'next/router';
import { useRouter } from 'next/router';

import { useCallback, useState, memo, Fragment } from 'react';

import { DESKTOP_MEDIA } from '@common/constants/MEDIAS';
import { css } from '@emotion/react';
import { HeaderV2Options } from '@type/web/header';

import AppDownloadBanner from '@components/Home/AppDownloadBanner';

import SearchFilterMobile from '../search/common/filter/SearchFilterMobile';
import CategoryListBtns from './CategoryListBtns';
import DesktopTopHeader from './DesktopTopHeader';
import HeaderPopup from './HeaderPopup';
import MobileTopHeader from './MobileTopHeader';
import NavigationBar from './NavigationBar';
import SearchBar from './SearchBar';

type HeaderV2Props = Omit<HeaderV2Options, 'isFixed'>;

const HeaderV2 = ({
  showAppDownloadBtn,
  showNavigationBar,
  title,
}: HeaderV2Props) => {
  const router = useRouter();
  const [showPopup, setShowPopup] = useState(false);
  const showSearchFilters = router.pathname.includes('/search');
  const togglePopup = useCallback(() => {
    /** 스크롤 고정 기능 */
    if (!showPopup) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    /** ------------- */
    /** 카테고리 메뉴 노출 토글 */
    setShowPopup(!showPopup);
    /** ------------- */
  }, [showPopup]);

  return (
    <Fragment>
      {router.pathname === '/' && <AppDownloadBanner />}
      <header css={headerWrapper}>
        {/** Top 헤더 : 홈 아이콘, 검색 및 카테고리 메뉴 보기 버튼, 앱으로 보기 버튼 */}
        <MobileTopHeader
          toggleCategoryMenu={togglePopup}
          showAppDownloadBtn={showAppDownloadBtn}
          title={title}
        />
        <DesktopTopHeader togglePopup={togglePopup} title={title} />
        {/** ------------------------- */}
        {/** Bottom 헤더 : Navigation bar */}
        <nav css={navigationBarWrapper}>
          <NavigationBar showNavigationBar={showNavigationBar} />
          {/** 검색 바 및 카테고리 메뉴 (팝업 형태) */}
          <HeaderPopup
            isDisplay={showPopup}
            togglePopup={togglePopup}
            isFullPage
          >
            <SearchBar togglePopup={togglePopup} />
            <CategoryListBtns togglePopup={togglePopup} />
          </HeaderPopup>
          {/** ------------------------- */}
        </nav>
        {/** ------------------------- */}
      </header>
      {showSearchFilters && (
        <div css={searchFiltersWrapper}>
          <SearchFilterMobile />
        </div>
      )}
    </Fragment>
  );
};

export default memo(HeaderV2);

const headerWrapper = css`
  width: 100%;
  position: relative;
`;

const navigationBarWrapper = css`
  min-width: 300px;
`;

const searchFiltersWrapper = css`
  ${DESKTOP_MEDIA} {
    display: none;
  }
`;
