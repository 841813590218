import Link from 'next/link';

import { ReactElement } from 'react';

import { hexToRgba } from '@common/parser';
import { mainList } from '@data/categoryList';
import { css } from '@emotion/react';

import { palette } from '@lib/styles/palette';

import SpaceLayout from '@components/web/Layout/SpaceLayout';

interface DesktopCategoryMenuProps {
  onMouseLeave: () => void;
  categoryPopup: boolean;
}
function DesktopCategoryMenu({
  onMouseLeave,
  categoryPopup,
}: DesktopCategoryMenuProps): ReactElement {
  const categoryListData = mainList.data.category;
  return (
    <div css={categoryWrap(categoryPopup)}>
      <div css={dim} />
      <div className="content" onMouseLeave={onMouseLeave}>
        <SpaceLayout padding={[45, 68]}>
          <p className="title">CATEGORY</p>
          <ul>
            {categoryListData?.map((item) => (
              <li key={`${item.categorySeq}_${item.categoryName}`}>
                <Link href={`/search?category=${item.categorySeq}`} passHref>
                  <a
                    css={categoryLink}
                    className={`category-${item.categoryName}`}
                  >
                    {item.categoryName}
                  </a>
                </Link>
              </li>
            ))}
          </ul>
        </SpaceLayout>
      </div>
    </div>
  );
}

const categoryWrap = (categoryPopup: boolean) => css`
  display: ${categoryPopup ? 'block' : 'none'};
  width: 100%;
  position: fixed;
  width: 100%;
  top: 196px;
  left: 0;
  z-index: 9;

  .content {
    background: ${palette.white};
    border-top: 1px solid ${palette.gray0};
    position: relative;
    z-index: 1;
    .title {
      font-size: 20px;
    }
    ul {
      display: flex;
      margin-top: 16px;
      flex-wrap: wrap;
      align-items: flex-start;

      li {
        display: flex;
        flex: 1;
        align-items: flex-start;
        min-width: 25%;
        max-width: 25%;
        padding: 12px 32px 12px 0;
      }
    }
  }
`;

const dim = css`
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  position: absolute;
  top: 0;
  left: 0;
`;

const categoryLink = css`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: none;
  outline: none;
  padding: 0;
  font-size: 14px;
  border-radius: 0.25rem;
  background-color: transparent;
  border: none;
  color: ${palette.gray6};
  justify-content: center;
  gap: 0 0.5rem;
  &:hover {
    color: ${hexToRgba(palette.gray6, 0.7)};
  }
`;

export default DesktopCategoryMenu;
