import { ReactNode } from 'react';

import { css } from '@emotion/react';

import useKeyDown from '@lib/hook/useKeyDown';
import { palette } from '@lib/styles/palette';

import Button from '@components/common/Button/Button';
import Icon from '@components/common/Icon/Icon';

import SpaceLayout from '../Layout/SpaceLayout';

interface HeaderPopupProps {
  children: ReactNode;
  togglePopup: () => void;
  isFullPage?: boolean;
  isDisplay: boolean;
}
const HeaderPopup = ({
  children,
  togglePopup,
  isFullPage = false,
  isDisplay,
}: HeaderPopupProps): JSX.Element => {
  useKeyDown('Escape', togglePopup);

  return (
    <div css={wrapper(isDisplay)}>
      <div className="layout">
        <div className="content">
          <SpaceLayout>
            <div css={closeButton}>
              <Button type="link" padding="0" onClick={togglePopup}>
                <Icon icon="close" stroke={palette.black10} />
              </Button>
            </div>
          </SpaceLayout>
          <SpaceLayout>{children}</SpaceLayout>
        </div>
        <div css={dim} onClick={togglePopup} />
      </div>
    </div>
  );
};

export default HeaderPopup;

const wrapper = (isDisplay: boolean) => css`
  display: ${isDisplay ? 'block' : 'none'};
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  z-index: 10;
  .layout {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  .content {
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    overflow-y: scroll;
  }
`;

const closeButton = css`
  display: flex;
  justify-content: end;
  height: 48px;
  margin-top: 20px;
  padding: 16px 0;
`;

const dim = css`
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
`;
