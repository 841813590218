import {
  ReactElement,
  useEffect,
  Ref,
  forwardRef,
  useState,
  Fragment,
} from 'react';

import { Radio, Space, RadioChangeEvent } from 'antd';
import { useRecoilState } from 'recoil';

import { css } from '@emotion/react';

import { palette } from '@lib/styles/palette';

import useCurrentLocation from '@hook/useCurrentLocation';
import usePortal from '@hook/usePortal';
import useResponsive from '@hook/useResponsive';
import useRouterHook from '@hook/useRouterHook';

import IconLoading from '@components/web/common/icons/IconLoading';

import {
  searchFilterData,
  mobileFilterSave,
  searchFilterDataV3,
} from '@recoils/search/atom';

function FilterLocation(
  { props }: any,
  ref: Ref<HTMLDivElement>,
): ReactElement {
  const { isPC } = useResponsive();
  const { pushQueryString, queryString, router } = useRouterHook();
  const {
    initGeo,
    location: currentLocation,
    error: currentError,
    isLoading,
  } = useCurrentLocation();

  // 검색필터 데이터
  const { PopUp, onShowPopUp, onHidePopUp } = usePortal('dim', 0.1);
  const [searchFilter, setSearchFilter] = useRecoilState(searchFilterDataV3);
  const [saveMobileFilter, setSaveMobileFilter] =
    useRecoilState(mobileFilterSave);
  const [radioValue, setRadioValue] = useState(
    queryString.lat || queryString.lon ? 1 : 0,
  );

  const activeFilter = (parameter: Record<string, string>) => {
    if (isPC) {
      const path = router.asPath.split('?')[0];
      pushQueryString(parameter, path);
    } else {
      setSaveMobileFilter({
        ...saveMobileFilter,
        ...parameter,
      });
    }
  };

  // 검색필터 변경 : 등록기간
  const changeDateRange = (e: RadioChangeEvent) => {
    const { value } = e.target;
    setRadioValue(value);

    if (value === 1) {
      if (currentLocation) {
        pushLocationURL();
      } else {
        initGeo();
      }
    } else {
      setSearchFilter({
        ...searchFilter,
        locationFilter: undefined,
      });

      if (queryString.lat || queryString.lon) {
        const parameter: Record<string, string> = {
          lat: '',
          lon: '',
        };

        activeFilter(parameter);
      }
    }
  };

  const pushLocationURL = () => {
    if (currentLocation !== null) {
      const parameter: Record<string, string> = {
        lat: currentLocation.latitude.toString(),
        lon: currentLocation.longitude.toString(),
      };

      activeFilter(parameter);
    }
  };

  useEffect(() => {
    pushLocationURL();
  }, [currentLocation]);

  useEffect(() => {
    if (isLoading) onShowPopUp();
    else onHidePopUp();
  }, [isLoading]);

  // useEffect(() => {
  //   console.log('currentError', currentError);
  // }, [currentError]);

  return (
    <Fragment>
      <PopUp>
        <IconLoading type="CenterLayout" />
      </PopUp>
      <div ref={ref}>
        <Radio.Group
          onChange={changeDateRange}
          value={radioValue}
          css={radioWrap}
          className="location"
        >
          <Space css={radioSpace} size={0} direction="vertical">
            <Radio value={0}>
              <span>모든 동네보기</span>
            </Radio>
            <Radio value={1}>
              <span>현 위치로 보기</span>
              <span>
                {(searchFilter.locationFilter && searchFilter.locationName) ||
                  ''}
              </span>
            </Radio>
          </Space>
        </Radio.Group>
      </div>
    </Fragment>
  );
}

const radioWrap = css`
  width: 100%;

  .ant-radio-wrapper {
    width: 100%;

    & > span:last-of-type {
      width: 100%;
    }

    .ant-radio-inner {
      border-width: 5px;

      &::after {
        content: none;
      }
    }
  }
`;

const radioSpace = css`
  width: 100%;

  .ant-radio-wrapper {
    height: 48px;
    padding: 14px 0;

    & > span:last-child {
      display: flex;
      justify-content: space-between;

      span:not(:first-child) {
        color: ${palette.orange9};
      }
    }
  }
`;

const FilterLocationRef = forwardRef<HTMLDivElement>(FilterLocation);
export default FilterLocationRef;
