import Link from 'next/link';

import React, { useState, MouseEventHandler, useMemo } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CategoryMenuType } from '@type/web/category';

import { palette } from '@lib/styles/palette';

import useRouterHook from '@hook/useRouterHook';

const FilterCategoryItem = ({
  category,
  parentSeqs,
  isAll = false,
}: {
  category: CategoryMenuType;
  parentSeqs: number[];
  isAll?: boolean;
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const { children, seq, name } = category;
  const { updateQueryString } = useRouterHook();

  const isLastSubCategory = parentSeqs?.length >= 3;
  const hasDropdown = useMemo(() => children && !isAll, [children, isAll]);

  const categorySearchPath = useMemo(() => {
    const lastCategorySeq = parentSeqs?.[parentSeqs.length - 1];
    return `/search?${updateQueryString({
      category: `${lastCategorySeq}`,
      page: '1',
    })}`;
  }, [parentSeqs]);

  const onClickHandler: MouseEventHandler = (e) => {
    e.stopPropagation();
    setOpenDropdown((v) => !v);
  };

  return (
    <Wrapper
      indentCount={(isAll ? parentSeqs?.length : parentSeqs?.length - 1) || 0}
    >
      {hasDropdown ? (
        <CategoryName openDropdown={openDropdown} onClick={onClickHandler}>
          {isLastSubCategory && '- '}
          {isAll ? '전체' : name}
        </CategoryName>
      ) : (
        <Link href={categorySearchPath} shallow={true}>
          <a className="filter-category">
            <CategoryName>
              {isLastSubCategory && '- '}
              {isAll ? '전체' : name}
            </CategoryName>
          </a>
        </Link>
      )}
      {openDropdown && hasDropdown && (
        <>
          <FilterCategoryItem
            key={seq}
            category={category}
            parentSeqs={parentSeqs}
            isAll
          />
          {children?.map((v: CategoryMenuType) => (
            <FilterCategoryItem
              key={v.seq}
              category={v}
              parentSeqs={[...parentSeqs, v.seq]}
            />
          ))}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div(
  ({ indentCount }: { indentCount: number }) => css`
    margin-left: ${indentCount * 12}px;
  `,
);

const CategoryName = styled.div(
  ({ openDropdown }: { openDropdown?: boolean }) => css`
    font-size: 14px;
    padding: 8px 0;
    color: ${openDropdown ? palette.black10 : palette.gray6};
    cursor: pointer;
  `,
);

export default FilterCategoryItem;
