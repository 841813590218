import { useState, useEffect } from 'react';

import useBoolean from './useBoolean';

const geolocationOptions: PositionOptions = {
  enableHighAccuracy: true,
  timeout: 1000 * 60 * 1, // 1 min (1000 ms * 60 sec * 1 minute = 60 000ms)
  maximumAge: 1000 * 3600 * 24, // 24 hour
};

interface LocationType {
  latitude: number;
  longitude: number;
}

const useCurrentLocation = (options = geolocationOptions) => {
  const { value: isLoading, setValue: setIsLoading } = useBoolean(false);
  const [locationOption, setLocationOption] = useState<PositionOptions | null>(
    null,
  );

  // location 정보 저장
  const [location, setLocation] = useState<LocationType | null>(null);
  // 에러 메세지 저장
  const [error, setError] = useState('');

  // Geolocation의 `getCurrentPosition` 메소드에 대한 성공 callback 핸들러
  const handleSuccess = (pos: GeolocationPosition) => {
    const { latitude, longitude } = pos.coords;

    setLocation({
      latitude,
      longitude,
    });
  };

  // Geolocation의 `getCurrentPosition` 메소드에 대한 실패 callback 핸들러
  // https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError/code
  const handleError = (err: GeolocationPositionError) => {
    if (err.code === err.PERMISSION_DENIED) {
      console.log('유저가 위치정보를 원하지 않습니다.');
    } else {
      console.log(err);
    }

    setError(err.message);
  };

  const getLocationPosData = async (geolocation: Geolocation) => {
    try {
      setIsLoading(true);
      const result = await new Promise<GeolocationPosition>((resolve, reject) =>
        geolocation.getCurrentPosition(resolve, reject, options),
      );
      handleSuccess(result);
    } catch (e) {
      handleError(e as GeolocationPositionError);
    } finally {
      setIsLoading(false);
    }
  };

  const initGeo = () => {
    setLocationOption(options);
  };

  useEffect(() => {
    if (locationOption !== null) {
      const { geolocation } = navigator;

      // 사용된 브라우저에서 지리적 위치(Geolocation)가 정의되지 않은 경우 오류로 처리합니다.
      if (!geolocation) {
        setError('현재 위치정보를 가져올 수 없습니다. 다시 시도해주세요.');
        return;
      }

      // Geolocation API 호출
      getLocationPosData(geolocation);
    }
  }, [locationOption]);

  return { initGeo, location, error, isLoading };
};

export default useCurrentLocation;
