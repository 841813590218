import { ReactNode } from 'react';
import { isDesktop } from 'react-device-detect';

import { DESKTOP_MEDIA } from '@common/constants/MEDIAS';
import { css } from '@emotion/react';
import { HeaderV2Options } from '@type/web/header';

import { palette } from '@lib/styles/palette';

import HeaderV2 from '../Header/HeaderV2';
import Footer from './Footer';

interface LayoutProps {
  headerOptions: HeaderV2Options;
  children: ReactNode;
}

const AppLayout = ({
  headerOptions: {
    isFixed,
    showAppDownloadBtn = false,
    showNavigationBar,
    title,
  },
  children,
}: LayoutProps): JSX.Element => {
  return (
    <div css={wrapper}>
      <div css={header(isFixed?.pc, isFixed?.tablet)}>
        <HeaderV2
          showAppDownloadBtn={showAppDownloadBtn}
          showNavigationBar={showNavigationBar}
          title={title}
        />
      </div>
      <div className="container">{children}</div>
      <div className="footerWrap">
        <Footer />
      </div>
    </div>
  );
};

export default AppLayout;

const wrapper = css`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* background: transparent; // 기본 스타일 제거 */
  position: relative;

  .container {
    flex: 1;
    width: 100%;
    background: #fff;
  }
  .footerWrap {
    width: 100%;
    align-self: flex-end;
    padding: 48px 15px;
    color: ${palette.gray5};
    background-color: ${palette.gray0};
    font-size: 12px;
  }
`;

const header = (
  pcIsFixed: boolean | undefined,
  tabletIsFixed: boolean | undefined,
) => css`
  width: 100%;
  background: rgba(255, 255, 255, 1);
  position: ${pcIsFixed ? 'fixed' : 'sticky'};
  top: 0;
  z-index: 99;

  @media (max-width: 1023px) {
    position: ${tabletIsFixed ? 'fixed' : 'sticky'};
  }
  ${DESKTOP_MEDIA} {
    background-color: white;
  }
`;
