import Image from 'next/image';
import Link from 'next/link';

import { MOBILE_MEDIA } from '@common/constants/MEDIAS';
import { css } from '@emotion/react';

import useResponsive from '@lib/hook/useResponsive';

import TabletAppDownloadImage from '../../asset/img/appdownloadbanner/tablet/appdownload.png';
import TabletIntroduceImage from '../../asset/img/appdownloadbanner/tablet/introduce.png';
import WebAppDownloadImage from '../../asset/img/appdownloadbanner/web/appdownload.png';
import WebIntroduceImage from '../../asset/img/appdownloadbanner/web/introduce.png';

export default function AppDownloadBanner() {
  const { isPC } = useResponsive();
  return (
    <div css={wrapper}>
      <Link href="/search">
        <a css={introductWrapper}>
          <Image
            src={isPC ? WebIntroduceImage : TabletIntroduceImage}
            width={isPC ? 512 : 300}
            height={60}
            layout="fixed"
            alt="중고나라 카테고리 페이지 이동 이미지"
            quality={100}
          />
        </a>
      </Link>
      <Link href="/appdownload">
        <a css={downloadWrapper}>
          <Image
            src={isPC ? WebAppDownloadImage : TabletAppDownloadImage}
            width={isPC ? 512 : 300}
            height={60}
            layout="fixed"
            alt="중고나라 앱 다운로드 페이지 이동 이미지"
            quality={100}
          />
        </a>
      </Link>
    </div>
  );
}
const wrapper = () => css`
  display: flex;
  height: 60px;
  cursor: pointer;
  ${MOBILE_MEDIA} {
    display: none;
  }
`;
const introductWrapper = () => css`
  width: 50%;
  background-color: #ffe195;
  text-align: right;
`;
const downloadWrapper = () => css`
  width: 50%;
  background-color: #ff6900;
  text-align: left;
`;
